\:root
  --code-color-default: #24292e
  --code-color-title: #6f42c1
  --code-color-doctag: #d73a49
  --code-color-attr: #005cc5
  --code-color-regexp: #032f62
  --code-color-built_in: #e36209
  --code-color-comment: #6a737d
  --code-color-quote: #22863a
  --code-color-del: #b31d28
  --code-color-delbg: #ffeef0
  --code-color-bl: #735c0f
  --code-color-addbg: #f0fff4

html.is_dark, html.is-dark
  --code-color-default: #c5c8c6
  --code-color-title: #b294bb
  --code-color-doctag: #cc6666
  --code-color-attr: #81a2be
  --code-color-regexp: #A3B4BF
  //  #8abeb7
  --code-color-built_in: #de935f
  --code-color-comment: #969896
  --code-color-quote: #b5bd68
  --code-color-del: #d54e53
  --code-color-delbg: #373b41
  --code-color-bl: #f0c674
  --code-color-addbg: #282a2e

.hljs
  color: var(--code-color-default)

  .hljs-subst
  color: var(--code-color-default)

.hljs-doctag,
.hljs-keyword,
.hljs-meta .hljs-keyword,
.hljs-template-tag,
.hljs-template-variable,
.hljs-type,
.hljs-variable.language_
  color: var(--code-color-doctag)

.hljs-title,
.hljs-title.class_,
.hljs-title.class_.inherited__,
.hljs-title.function_
  color: var(--code-color-title)

.hljs-section,
.hljs-attr,
.hljs-attribute,
.hljs-literal,
.hljs-meta,
.hljs-number,
.hljs-operator,
.hljs-variable,
.hljs-selector-attr,
.hljs-selector-class,
.hljs-selector-id
  color: var(--code-color-attr)

.hljs-regexp,
.hljs-string,
.hljs-meta .hljs-string
  color: var(--code-color-regexp)

.hljs-built_in,
.hljs-symbol
  color: var(--code-color-built_in)

.hljs-comment,
.hljs-code,
.hljs-formula
  color: var(--code-color-comment)

.hljs-name,
.hljs-quote,
.hljs-selector-tag,
.hljs-addition,
.hljs-selector-pseudo
  color: var(--code-color-quote)

.hljs-bullet
  color: var(--code-color-bl)

.hljs-emphasis
  font-style: italic

.hljs-strong,
.hljs-section
  font-weight: bold

.hljs-addition
  background-color: var(--code-color-addbg)

.hljs-deletion
  color: var(--code-color-del)
  background-color: var(--code-color-bg)

.hljs-char.escape_,
.hljs-link,
.hljs-params,
.hljs-property,
.hljs-punctuation,
.hljs-tag